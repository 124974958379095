<script setup lang='ts'>
const props = withDefaults(defineProps<{
  iconClass?: string
  text?: string
  textClass?: string
}>(), {
  iconClass: '',
})
</script>

<template>
  <div grid place-items-center text-gray-4 w-full>
    <i :class="props.iconClass || 'i-custom-undraw-void?bg text-25 mt-4'" />
    <p :class="props.textClass || 'text-3 py-sm'">
      {{ props.text || '暂无相关内容' }}
    </p>
  </div>
</template>
